import $ from 'jquery';
import UIkit from 'uikit';

$(document).ready(function ($) {
    if ($('.wow-popup').length) {
        const timeToShow = $('.wow-popup').data('time-to-show');
        const timeToShowPopupFromLastVisit = 1 * timeToShow;
        const currentTime = Math.floor(Date.now() / 1000);

        if (localStorage && localStorage.lastShowPopup) {
            if (currentTime - localStorage.lastShowPopup < timeToShowPopupFromLastVisit) {
                return;
            }
        }

        UIkit.modal('#wow-popup').show();

        if (localStorage) {
            localStorage.setItem("lastShowPopup", currentTime);
        }
    }
});