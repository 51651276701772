// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_uikit.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

import './components/_count-down.js'; import './components/_mobile-menu.js'; import './components/_popup.js'; import './components/_scroll-to-top.js'; import './components/_share-product.js';